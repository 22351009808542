.resources {
    min-height: 100vh;

    .resources-container {
        justify-content: center;

        .resources-card {
            position: relative;
            width: 250px;
            aspect-ratio: 1/1.4142;
            background-color: #fff;
            transform-style: preserve-3d;
            transform: perspective(1000px);
            box-shadow: var(--card-shadow);
            transition: 1s;

            &:hover {
                transform: translateX(50%);

                .resources-card-image {
                    transform: rotateY(-180deg);
                } 
            }

            &-image {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 1;
                transform-origin: left;
                transform-style: preserve-3d;
                background-color: #000;
                transition: 1s;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform-style: preserve-3d;
                }
            }

            &-details {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                &-content {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;

                    h3 {
                        text-align: center;
                    }

                    p {
                        text-align: justify;
                        font-size: .9rem;
                        line-height: 125%;
                        text-indent: 25px;
                    }

                    a {
                        color: var(--title-color);
                        background-color: lightpink;
                        padding: 7px 10px;
                        border-radius: 5px;
                        transition: all .5s ease;
                        cursor: pointer;

                        &:hover {
                            background-color: rgba(lightpink, .8);
                        }
                    }
                }
            }
        }
    }
}

// Responsive
@media screen and (max-width: 575px) {
    .resources {
        .resources-container {
            .resources-card {
                width: 200px;

                &-details {
                    padding: 10px;

                    &-content {
                        h3 {
                            line-height: 120%;
                            font-size: .9rem;
                        }

                        p {
                            font-size: .8rem;
                            line-height: 120%;
                        }

                        a {
                            padding: 3px 7px;
                            font-size: 0.85rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .resources {
        .resources-container {
            .resources-card {
                width: 175px;

                &-details {
                    &-content {
                        h3 {
                            font-size: .75rem;
                        }

                        p {
                            font-size: .675rem;
                        }

                        a {
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}