.experience-sections {
    display: grid;
    grid-template-columns: .5fr;
    justify-content: center;

    .experience-data {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        column-gap: 1.5rem;

        .experience-content {
            padding: .15rem 0;

            &.align-right {
                text-align: right;
            }
        }

        .experience-title {
            font-size: var(--normal-font);
            font-weight: var(--font-medium);
        }
        
        .experience-subtitle {
            display: inline-block;
            font-size: var(--small-font);
            margin-bottom: 1rem;
        }
        
        .experience-date {
            font-size: var(--small-font);

            i {
                margin-right: 5px;
            }
        }
        
        .experience-round {
            display: inline-block;
            width: 13px;
            height: 13px;
            background-color: var(--text-color);
            border-radius: 50%;
        }
        
        .experience-line {
            display: block;
            width: 1px;
            height: 100%;
            background-color: var(--text-color);
            transform: translate(6px, -7px);
            
            &.fade {
                background: linear-gradient(180deg, var(--text-color) 0%, var(--text-color) 50%, transparent 100%);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .experience-sections {
        grid-template-columns: 1fr;

        .experience-data {
            column-gap: 1rem;
        }
    }
}

@media screen and (max-width: 400px) {
    .experience-sections {
        .experience-data {
            column-gap: .75rem;
        }
    }
}