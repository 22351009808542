.scroll-top {
    position: fixed;
    right: 2rem;
    bottom: -20%;
    background-color: var(--title-color);
    padding: .3rem .5rem;
    border-radius: .4rem;
    z-index: 10;
    opacity: 0;
    transition: opacity 1.25s ease-in-out, background-color .4s ease-in-out, bottom .4s ease-in-out, transform .4s ease-in-out;

    &.transition {
        opacity: 1;
    }

    // Show scroll-to-top button
    &.show-scroll {
        bottom: 2rem;
    }

    .scroll-top-icon {
        font-size: 1.5rem;
        color: #fff;
    }

    &:hover {
        background-color: var(--title-color-dark);
        transform: rotate(360deg);
    }

}


// Responsive 
@media screen and (max-width: 992px) {
    .scroll-top {
        right: 1.5rem;
        padding: .25rem .4rem;

        &-icon {
            font-size: 1.25rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .scroll-top {
        display: none;
    }
}