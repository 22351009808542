.about {
    .about-container {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 4rem;

        .about-img {
            width: 315px;
            border-radius: 1rem;
            justify-self: center;
            box-shadow: var(--card-shadow);
            transition: all .5s ease;
        }

        .about-data {
            .about-description {
                padding: 0 4rem 0 0;
                text-align: justify;
                text-indent: 25px;

                &.mb {
                    margin-bottom: 2.5rem;
                }

                &>a {
                    color: var(--title-color);
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}

// Responsive
@media screen and (max-width: 992px) {
    .about {
        .about-container {
            grid-template-columns: 1fr;
            row-gap: 2.5rem;

            .about-img {
                width: 225px;
            }

            .about-data {
                text-align: center;

                .about-description {
                    padding: 0 5rem;
                    margin-bottom: 2rem;
                }

            }
        }
    }
}

@media screen and (max-width: 576px) {
    .about {
        .about-container {
            .about-data {
                .about-description {
                    padding: 0;
                }
            }
        }
    }
}