.app-cursor {
    z-index: 9999;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #cccccc;
    pointer-events: none;
    overflow: hidden;
    position: fixed;
    transform: translate3d(0, 0, 0);
    mix-blend-mode: difference;
    opacity: 0;
    transition: opacity 1.25s ease-in-out;

    &.transition {
        opacity: 1;
    }
}

// Responsive 
@media screen and (max-width: 768px) {
    .app-cursor {
        display: none;
    }
}