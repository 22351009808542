@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--body-color);
    opacity: 0;
    // filter: blur(10px);
    transition: opacity 1.25s ease-in-out, filter 1.25s ease-in-out, box-shadow .3s ease;

    &.transition {
        opacity: 1;
        // filter: blur(0px);
    }

    // show the navbar after 100px
    &.header-show {
        box-shadow: var(--card-shadow);
    }

    .nav {
        height: calc(var(--header-height) + 1.5rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;

        &-logo {
            font-size: 2rem;
            font-family: 'Berkshire Swash', cursive;
            text-shadow: 1px 1px 0px #ededed, 3px 3px 0px rgba(0, 0, 0, 0.15);
            transition: all .25s ease;

            &:hover {
                text-shadow: none;
                transform: translate(1.5px, 1.5px);
            }
        }

        .nav-logo,
        .nav-toggle {
            color: var(--title-color);
            font-weight: var(--font-medium);
        }

        .nav-icon,
        .nav-close,
        .nav-toggle {
            display: none;
        }

        .nav-menu {
            .nav-list {
                display: flex;
                column-gap: 2rem;

                .nav-item {
                    .nav-link {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: var(--medium-font);
                        color: var(--title-color);
                        font-weight: var(--font-medium);
                        transition: all .3s ease;

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

// Active link
.active-link,
.nav-link:hover {
    color: var(--title-color-dark);
}

// Responsive
@media screen and (max-width: 768px) {
    .header {
        bottom: 0;
        top: initial;
        box-shadow: 1px -2px 7px rgba(0, 0, 0, 0.15);

        .nav {
            height: var(--header-height);

            &-logo {
                font-size: 1.5rem;
            }

            .nav-menu {
                position: fixed;
                bottom: -100%;
                left: 0;
                width: 100%;
                background-color: var(--body-color);
                padding: 2rem 1.5rem 4rem;
                box-shadow: 1px -3px 10px rgba(0, 0, 0, 0.15);
                border-radius: 1.5rem 1.5rem 0 0;
                transition: .5s;

                &.show-menu {
                    bottom: 0;
                }

                .nav-list {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 2rem;
                }
            }

            .nav-icon {
                font-size: 1.2rem;
            }

            .nav-close {
                position: absolute;
                right: 1.4rem;
                bottom: .5rem;
                font-size: 1.5rem;
                cursor: pointer;
                color: var(--title-color);
            }

            .nav-close {
                &:hover {
                    color: var(--title-color-dark);
                }
            }

            .nav-toggle {
                font-size: 1.15rem;
                cursor: pointer;
            }

            .nav-icon,
            .nav-close,
            .nav-toggle {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .nav-menu {
        padding: 2rem .25rem 4rem;

        .nav-list {
            column-gap: 0;
        }
    }
}